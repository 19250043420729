<template>
  <div class="engineering">
    <van-tabs sticky animated>
      <van-tab v-for="(item, index) in Treelist" :key="index">
        <template #title>
          <span @click="onClickTabs(item)" class="engineeringSpan">{{
            item.pftr02
          }}</span>
        </template>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getTreeInfo"
          :immediate-check="false"
        >
          <van-swipe-cell v-for="(el, inx) in list" :key="inx">
            <div
              class="myDiv"
              style="
                margin: 5px 0;
                height: auto;
                min-height: auto;
                border-radius: 0;
              "
              @click="wxClick(el)"
            >
              <div>{{ inx + 1 }} . {{ el.prfl02 }}</div>
            </div>
            <template #right>
              <van-button
                square
                type="danger"
                style="height: 100%; width: 100%"
                text="删除"
                @click="Delete(el)"
              />
            </template>
          </van-swipe-cell>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { GetProjectFileZTree, GetDataFileList, DelDataFile } from "@/api/index";
export default {
  name: "engineering",
  components: {},
  data() {
    return {
      loading: false,
      finished: false,
      pageSize: 0,
      list: [],
      Treelist: [],
      ids: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    async Delete(v) {
      let res = await DelDataFile({ fids: v.prfl01 });
      this.getTreeInfo();
    },
    onClickTabs(v) {
      this.ids = v.pftr01;
      this.getTreeInfo(v.pftr01);
    },
    wxClick(item) {
      let data = {
        imgList: [],
        fileList: [],
      };
      let type = item.prfl04.substr(item.prfl04.indexOf(".") + 1);
      if (type == "png" || type == "jpg") {
        data.imgList.push(this.$store.state.baseUrl + item.prfl04);
      } else {
        data.fileList.push(this.$store.state.baseUrl + item.prfl04);
      }
      wx.miniProgram.navigateTo({
        url: `/pages/download/index?data=` + JSON.stringify(data),
      });
    },
    async getData() {
      let res = await GetProjectFileZTree({
        params: {
          xid: this.$store.state.pid,
        },
      });
      let ids = res.Data.reduce((a, b, k) => {
        b["is"] = false;
        return a + b.pftr01 + (res.Data.length - 1 == k ? "" : ",");
      }, "");
      res.Data.unshift({ pftr01: ids, pftr02: "全部", is: true });
      this.Treelist = res.Data;
      this.ids = ids;
      this.getTreeInfo(ids);
    },
    async getTreeInfo(ids) {
      this.loading = true;
      this.pageSize += 5;
      let res = await GetDataFileList({
        params: {
          xid: this.$store.state.pid,
          uid: this.$store.state.uid,
          gid: ids || this.ids,
          type: 1,
          pageIndex: 1,
          pageSize: this.pageSize,
        },
      });
      this.list = res.Data.list;
      if (res.Data.list.length >= res.Data.total) {
        this.finished = true;
      }
      this.loading = false;
    },
    updateData() {
      this.list = [];
      this.Treelist = [];
      this.$emit("updateLoading", "工程资料");
      this.getData();
    },
  },
  created() {},
  mounted() {
    this.getData();
  },
};
</script>
<style lang='scss' scoped>
.engineering {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  /deep/ .van-tabs__line {
    background-color: rgb(255, 255, 255);
  }
  /deep/ .van-tabs__nav {
    background-color: rgba(255, 255, 255, 0);
  }
  .engineeringSpan {
    color: white;
  }
  /deep/ .van-tab__text {
    padding:0 10px;
  }
  /deep/ .van-tab--active .van-tab__text .engineeringSpan {
    position: relative;
    z-index: 1;
    min-width: 62px;
    text-align: center;
    display: block;
    color: rgb(0, 119, 255);
    &::after {
      content: "";
      position: absolute;
      left: -5px;
      z-index: -1;
      top: 0;
      background-color: rgb(255, 255, 255);
      width: 100%;
      padding: 0 5px;
      border-radius: 10px;
      height: 100%;
    }
  }
  /deep/ .van-tabs__content {
    height: 93%;
    overflow: hidden;
    overflow-y: auto;
  }
}
.headTree {
  width: 100%;
  height: 40px;
  overflow: hidden;
  overflow-y: auto;
  background-color: red;
}
</style>